html {
  background-color: #e6e3dc;
  cursor: crosshair;
}

body {
  margin: 0;
  padding: 0;
}

a {
  font-family: "Inconsolata", monospace;
  font-weight: 400;
  text-decoration: none;
  color: black;
}

a:hover {
  cursor: crosshair;
  font-weight: 600;
  background-color: #E0D3D3;
  color: black;
}